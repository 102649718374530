<template>
    <v-container>
        <v-row >
            <v-col >
            <s-toolbar   
                no-border 
                dark 
                label="Personas - Carga Masiva"
                color="#2955c8"
             >

            </s-toolbar>
			     
				<v-card >
					<v-col>
						<v-row>
						<v-col >
              				<s-import-excel
								v-if="dialogExcel"
								:headers = "headers"
								@loaded	="saveMassive($event)"
              				></s-import-excel>
			  			</v-col>
						</v-row>
					</v-col>
				</v-card>				
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
	
	import _PersonService from "@/services/General/PersonService";

	export default {
		data() {
            
			return {
				dialogExcel: true,
				headerDetail:[
					{ text: "TIPO DOC.", value: "TIPO_DOCUMENTO", width: 60 },
					{ text: "DOCUMENTO", value: "DOCUMENTO", width: 60 },
					{ text: "APELLIDO PATERNO", value: "APELLIDO_PATERNO", width: 60 },
					{ text: "APELLIDO MATERNO", value: "APELLIDO_MATERNO", width: 60 },
					{ text: "NOMBRE", value: "NOMBRE", width: 60 },
					{ text: "FECHA NACIMIENTO", value: "FECHA_NACIMIENTO", width: 60 },
					{ text: "GENERO", value: "GENERO", width: 60 },
				],
                headers: [
                    {
						column	: 	"TIPO_DOCUMENTO",
						value	: 	"TIPO_DOCUMENTO",
						text 	: 	"TIPO DOC."
					},
					{
						column	: 	"DOCUMENTO",
						value	: 	"DOCUMENTO",
						text 	: 	"DOCUMENTO"
					},	
					{
						column	: 	"APELLIDO_PATERNO",
						value	: 	"APELLIDO_PATERNO",
						text 	: 	"APELLIDO PATERNO"
					},
					{
						column	: 	"APELLIDO_MATERNO",
						value	: 	"APELLIDO_MATERNO",
						text 	: 	"APELLIDO MATERNO"
					},	
					{
						column	: 	"NOMBRE",
						value	: 	"NOMBRE",
						text 	: 	"NOMBRE"
					},		
					{
						column	: 	"FECHA_NACIMIENTO",
						value	: 	"FECHA_NACIMIENTO",
						text 	: 	"FECHA NACIMIENTO",
						date	: 	true
					},	
                    {
						column	: 	"GENERO",
						value	: 	"GENERO",
						text 	: 	"GENERO"
					},		
				 ],
			}
		},
        methods: {
            async saveMassive(items) {
				// console.log("items", items);
				for (const item of items) {
					const persona = {
						NtpPaternalSurname: item.APELLIDO_PATERNO,
						NtpMaternalSurname: item.APELLIDO_MATERNO,
						NtpName: item.NOMBRE,
						TypeSex: item.GENERO,
						NtpBirthDate: item.FECHA_NACIMIENTO,
						GenPerson: {
							PrsDocumentNumber: item.DOCUMENTO,
							TypePerson: 1,
							TypePersonDocument: item.TIPO_DOCUMENTO,
							NatID: 193,
						}
					};

					try {
						const response = await _PersonService.savenaturalperson(persona, this.$fun.getUserID());
						// console.log("Registro de Persona", response.data);
					} catch (error) {
						console.error("Error al registrar persona", item, error);
					}
				}

				this.dialogExcel = false;
				this.$fun.alert("Guardado Correctamente", "success");
				setTimeout(() => {
				this.dialogExcel = true;
				}, 10);
			},
        }
    }
</script>